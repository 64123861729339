import { useTheme } from "@emotion/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography
} from "@mui/material";
import parseHtml from "html-react-parser";
import { Subtitulo } from "../../";
import useAlert from "../../../hooks/useAlert";
import useStyle from "./style";
import TipoStatusPedido from "../../../util/typesTipoStatusPedido";
import { ButtonCustom } from "../../Botoes/Button";

// const exemplo = {
//    Chave: 'isso é um exemplo de chave',
//    Nome: 'Primecase Demonstração',
//    CPF: '123.231.123-23',
//    Banco: 'banco tal'
// }

const Pix = ({ pedido, margin }) => {
  const theme = useTheme();
  const { showAlertSuccess, showAlertError } = useAlert();
  const classes = useStyle();
  const handlerCopy = (text) => (event) => {
    event.preventDefault();

    if (!!!text) showAlertError("Não consta codigo para ser copiado!");

    navigator.clipboard
      .writeText(text)
      .then(() => {
        showAlertSuccess("Codigo copiado com sucesso !!");
      })
      .catch((error) => {
        showAlertError(`${error.message} - R339B`);
      });
  };

  return (
    <>

      {!!pedido?.formaPagamento?.pix ? (
        <>
          <Card className={classes.card} sx={{ margin: margin ?? 'auto' }} variant="outlined">
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                boxShadow: 'none',
                borderRadius: 'inherit',
              }}
            >
              <Subtitulo sx={{ color: "#060606" }}>
                Use o QR Code do Pix para pagar
              </Subtitulo>
              <Box>
                {pedido?.status?.id === TipoStatusPedido.Cancelado ? (
                  <>
                    <Subtitulo sx={{ color: "#060606", mt: 2 }}>
                      QR Code expirado ou cancelado
                    </Subtitulo>
                  </>
                ) : pedido?.status?.id === TipoStatusPedido.Pendente ? (
                  <img
                    className={classes.qrCode}
                    alt="Pix"
                    src={pedido?.formaPagamento?.pix?.pixQRCode}
                    loading="lazy"
                  />
                ) : (
                  <>
                    <span>Pagamento já efetuado</span>
                  </>
                )}
              </Box>

              {/* {pedido?.status?.id !== TipoStatusPedido.Cancelado && (
                <Titulo
                  color={"primary"}
                  sx={{ fontWeight: "bold", fontSize: 30, mt: 1 }}
                >
                  {pedido?.valorTotalMoedaNacional !== null &&
                    pedido?.valorTotalMoedaNacional !== undefined
                    ? pedido?.valorTotalMoedaNacional.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                    : pedido?.valorTotal.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                </Titulo>
              )} */}

              {
                pedido?.status?.id === TipoStatusPedido.Pendente &&
                <Box sx={{ margin: margin ?? 'auto' }}>
                  {pedido?.status?.id !== TipoStatusPedido.Cancelado && (
                    <ButtonCustom
                      onClick={handlerCopy(
                        pedido?.formaPagamento?.pix?.pixLinhaDigitavel
                      )}
                      sx={{
                        textTransform: "inherit",
                        fontSize: "11pt !important",
                        margin: 'auto',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: "center",
                      }}
                      variant="contained"
                    >
                      Copiar código da chave PIX
                      <ContentCopyIcon
                        sx={{ fontSize: 22, ml: theme.spacing(1) }}
                      />
                    </ButtonCustom>
                  )}
                  {pedido?.formaPagamento?.descricaoHtml?.length > 0 && (
                    <p style={{ marginTop: "1rem" }}>
                      <b>Atenção:</b> {parseHtml(pedido?.formaPagamento?.descricaoHtml || "")}
                    </p>
                  )}
                </Box>
              }


            </CardContent>
          </Card>
        </>
      ) : (
        <>
          <Card
            variant="outlined"
            sx={{
              borderColor: theme.palette.primary.main,
              borderWidth: "1px",
              borderRadius: 4,
              background: theme.palette.primary.main,
              minHeight: "20vh",
              maxWidth: "70vh",
            }}
          >
            <CardContent
              sx={{
                maxHeight: "50vh",
                overflow: "auto",
              }}
            >
              <Subtitulo sx={{ color: "#fff" }}>
                Use os dados de pix para realizar o pagamento
              </Subtitulo>
              <hr />

              {!!pedido?.formaPagamento?.descricaoHtml && (
                <>
                  <Subtitulo sx={{ fontWeight: "bold", mb: 2, color: "#fff" }}>
                    {pedido?.formaPagamento?.descricao}
                  </Subtitulo>
                  <Subtitulo sx={{ color: "#ddd" }}>
                    {parseHtml(pedido?.formaPagamento?.descricaoHtml ?? "")}
                  </Subtitulo>
                </>
              )}
            </CardContent>
          </Card>
        </>
      )}

      {!!pedido?.formaPagamento?.pix &&
        pedido?.status?.id === TipoStatusPedido.Pendente &&
        <>
          <hr />
          <Box className={classes.card}>
            <ul className={classes.listaInformacoes}>
              <li className={classes.itemList}>
                <Typography sx={{ fontWeight: 600 }}>
                  Chave Pix
                </Typography>
                <Typography>
                  {pedido?.formaPagamento?.pix?.pixLinhaDigitavel}
                  <IconButton
                    className={classes.iconButton}
                    onClick={handlerCopy(
                      pedido?.formaPagamento?.pix?.pixLinhaDigitavel
                    )}
                  >
                    <ContentCopyIcon
                      sx={{
                        fontSize: 20,
                        color: `${theme.palette.primary.main}`,
                      }}
                    />
                  </IconButton>{" "}
                </Typography>
              </li>
            </ul>
          </Box>
        </>
      }
    </>
  );
};

export default Pix;
