
import api from '../index';

export const GetAtividades = async (idTipoCliente) => {
   try {
      const { data: response, status } = await api.get(`/TipoAtividade/GetAllByIDTipoCliente?idTipoCliente=${idTipoCliente}`)

      return {
         status,
         errorMessage: response.errorMessage,
         data: response.data,
      }
   } catch (error) {
      throw new Error(error + "RJAZD");
   }
}

